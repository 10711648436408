import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Informações da Empresa */}
        <div className="footer-about">
          <h2>Rede Industrial</h2>
          <p>
            A Rede Industrial é referência em soluções tecnológicas e inovadoras
            para a indústria. Focamos em qualidade, segurança e eficiência.
          </p>
        </div>

        {/* Links Úteis */}
        <div className="footer-links">
          <h3>Links Úteis</h3>
          <ul>
            <li><a href="/sobre">Sobre nós</a></li>
            <li><a href="/servicos">Serviços</a></li>
            <li><a href="/contato">Contato</a></li>
            <li><a href="/privacidade">Política de Privacidade</a></li>
          </ul>
        </div>

        {/* Redes Sociais */}
        <div className="footer-socials">
          <h3>Redes Sociais</h3>
          <ul>
            <li><a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i> Facebook</a></li>
            <li><a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i> LinkedIn</a></li>
            <li><a href="https://www.instagram.com/redeindustrialoficial/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i> Instagram</a></li>
          </ul>
        </div>
      </div>

      {/* Copyright */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Rede Industrial. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

export default Footer;
