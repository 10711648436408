import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const CustomPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Alterado para usar o proxy '/api' em vez do endpoint completo
        const response = await axios.get('/api/v1/horimetros/', { 
          headers: {
            Authorization: 'Bearer 32088|ruXHIfT49kQqHJG1Udcxg9xesJYD7KrRQyybWw7o6f800539'
          }
        });
        setData(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const prepareChartData = (data) => {
    const statuses = data.reduce((acc, item) => {
      if (item.status_api in acc) {
        acc[item.status_api].count += 1;
        acc[item.status_api].time_worked += parseTime(item.time_worked);
      } else {
        acc[item.status_api] = {
          count: 1,
          time_worked: parseTime(item.time_worked)
        };
      }
      return acc;
    }, {});

    const labels = Object.keys(statuses);
    const values = Object.values(statuses);

    return {
      labels,
      datasets: [
        {
          label: 'Número de Horímetros',
          data: values.map(val => val.count),
          backgroundColor: 'rgba(75, 192, 192, 0.6)'
        },
        {
          label: 'Tempo Trabalhado (horas)',
          data: values.map(val => val.time_worked),
          backgroundColor: 'rgba(153, 102, 255, 0.6)'
        }
      ]
    };
  };

  const parseTime = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours + minutes / 60 + seconds / 3600;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const chartData = prepareChartData(data);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Status dos Horímetros</h1>
      <Bar data={chartData} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px'
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px'
  }
};

export default CustomPage;

