import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaComments, FaBook, FaSignInAlt, FaMoon, FaSun, FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';

function Navbar({ sidebar = "não", options = [] }) {
  const [darkMode, setDarkMode] = useState(false);  // Estado para o modo escuro
  const [sidebarOpen, setSidebarOpen] = useState(false);  // Estado para controlar sidebar
  const [scrolled, setScrolled] = useState(false);  // Estado para controlar o scroll do Navbar
  const navbarRef = useRef(null);

  // Alterna o modo escuro
  const toggleTheme = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode');
  };

  // Alterna a exibição do sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Monitora o scroll para aplicar a classe "scrolled" no navbar
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {/* Navbar fixo no topo com comportamento de scroll */}
      <nav ref={navbarRef} className={`navbar ${scrolled ? 'scrolled' : ''}`}>
        <div className="navbar-left">
          {/* Toggle do sidebar */}
          {sidebar === "sim" && (
            <button className="sidebar-toggle" onClick={toggleSidebar}>
              {sidebarOpen ? <FaTimes /> : <FaBars />}
            </button>
          )}
          <Link to="/" className="brand">Chatbot Treinamento</Link>
        </div>

        <div className="navbar-center">
          <ul>
            <li><Link to="/"><FaHome /> Início</Link></li>
            <li><Link to="/chat"><FaComments /> Chat</Link></li>
            <li><Link to="/docs"><FaBook /> Documentação</Link></li>
            <li><Link to="/custom"><FaBook /> Custom</Link></li>
          </ul>
        </div>

        <div className="navbar-right">
          <Link to="/login" className="login-link">
            <FaSignInAlt /> Login
          </Link>
          <button onClick={toggleTheme} className="theme-toggle">
            {darkMode ? <FaSun /> : <FaMoon />}
          </button>
        </div>
      </nav>

      {/* Sidebar que abre ao clicar no botão */}
      {sidebar === "sim" && (
        <div className={`sidebar ${sidebarOpen ? 'sidebar-open' : ''}`} style={{ top: `${navbarRef.current?.offsetHeight}px` }}>
          <ul>
            {options.map((option, index) => (
              <li key={index}>
                <Link to={option.link}>
                  {option.icon} {option.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default Navbar;
