import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar'; // Corrigir o caminho para o Navbar
import Footer from '../../components/Footer/Footer'; // Corrigir o caminho para o Footer
import { FaUser, FaRobot } from 'react-icons/fa'; // Ícones para o usuário e o bot
import './ChatPage.css'; // Arquivo CSS para os estilos

function ChatPage() {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [darkMode] = useState(false);

  // Atualize a URL para o endpoint correto
  const API_URL = 'http://127.0.0.1:5000/generate-page'; // Altere conforme o novo endpoint

  const handleSendMessage = async () => {
    if (inputValue.trim() !== '') {
      const userMessage = { sender: 'user', text: inputValue };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      setInputValue('');

      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ descricao: inputValue }), // Envia a descrição para a nova API
        });

        if (!response.ok) {
          throw new Error('Erro ao se comunicar com o servidor');
        }

        const data = await response.json();
        // A nova API retorna o caminho do arquivo. Use isso para exibir uma resposta
        const botMessage = {
          sender: 'bot',
          text: `Página criada! Caminho do arquivo: ${data.file_path}`,
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);
      } catch (error) {
        console.error('Erro ao enviar mensagem:', error);
        const errorMessage = { sender: 'bot', text: 'Erro ao enviar mensagem. Tente novamente mais tarde.' };
        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }
    }
  };

  return (
    <div className={`page-container ${darkMode ? 'dark-mode' : ''}`}>
      <Navbar />
      <div className="content-wrap">
        <main className="chat-container">
          <div className="chat-window">
            <div className="chat-messages">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.sender}`}>
                  <div className="message-icon">
                    {msg.sender === 'user' ? <FaUser /> : <FaRobot />}
                  </div>
                  <div className="message-content">
                    <p>{msg.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="chat-input">
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="input-field"
                placeholder="Digite sua mensagem"
              />
              <button className="send-button" onClick={handleSendMessage}>
                Enviar
              </button>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default ChatPage;
