import React, { useState } from 'react';
import './AuthForm.css';  // Estilos

function AuthForm() {
  const [isRegistering, setIsRegistering] = useState(false); // Controla se o usuário está no modo cadastro ou login
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  // Função para login
  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Por favor, preencha todos os campos.');
    } else {
      setError('');
      console.log('Login realizado com sucesso!', { email, password });
      // Adicionar lógica de autenticação aqui
    }
  };

  // Função para cadastro
  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !password || !confirmPassword) {
      setError('Por favor, preencha todos os campos.');
    } else if (password !== confirmPassword) {
      setError('As senhas não coincidem.');
    } else {
      setError('');
      console.log('Cadastro realizado com sucesso!', { name, email, password });
      // Adicionar lógica de registro aqui
    }
  };

  return (
    <div className="auth-container">
      {isRegistering ? (
        <div className="register-form">
          <h1>Cadastre-se</h1>
          <form onSubmit={handleRegisterSubmit}>
            <div className="form-group">
              <label htmlFor="name">Nome:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Digite seu nome"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu e-mail"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Senha:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Digite sua senha"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Confirme a Senha:</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirme sua senha"
                required
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button type="submit" className="button">Cadastrar</button>
          </form>
          <p>
            Já tem uma conta?{' '}
            <button onClick={() => setIsRegistering(false)} className="toggle-button">
              Faça login
            </button>
          </p>
        </div>
      ) : (
        <div className="login-form">
          <h1>Login</h1>
          <form onSubmit={handleLoginSubmit}>
            <div className="form-group">
              <label htmlFor="email">E-mail:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu e-mail"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Senha:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Digite sua senha"
                required
              />
            </div>
            {error && <p className="error">{error}</p>}
            <button type="submit" className="button">Entrar</button> {/* Mesma classe 'button' */}
          </form>
          <p>
            Não tem uma conta?{' '}
            <button onClick={() => setIsRegistering(true)} className="toggle-button">
              Cadastre-se
            </button>
          </p>
        </div>
      )}
    </div>
  );
}

export default AuthForm;
