import React from 'react';
import AuthForm from '../../components/Auth/AuthForm';  // Importa o componente AuthForm
import Navbar from '../../components/Navbar/Navbar';    // Navbar continua a mesma
import { FaHome, FaInfoCircle, FaUserAlt } from 'react-icons/fa'; // Importa ícones

function LoginPage() {
  const sidebarOptions = [
    { name: 'Home', link: '/', icon: <FaHome /> },
    { name: 'Sobre', link: '/about', icon: <FaInfoCircle /> },
    { name: 'Perfil', link: '/profile', icon: <FaUserAlt /> }
  ];

  return (
    <div>
      <Navbar sidebar="sim" options={sidebarOptions} /> {/* Ativa o sidebar */}
      <div style={{ padding: '80px 20px' }}> {/* Adiciona padding para dar espaço ao navbar fixo */}
        <AuthForm />  {/* Usa o componente AuthForm para renderizar login e cadastro */}
      </div>
    </div>
  );
}

export default LoginPage;
