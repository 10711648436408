import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Importa o useNavigate para navegar entre rotas
import { FaComments, FaBook, FaCheckCircle, FaRocket, FaClock } from 'react-icons/fa';
import './HomePage.css';
import Navbar from '../../components/Navbar/Navbar'; // Importando o componente Navbar
import Footer from '../../components/Footer/Footer'; // Usando o componente Footer

function Home() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();  // Hook para navegação

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode');
  };

  const sidebarOptions = [
    { name: 'Início', link: '/', icon: <FaComments /> },
    { name: 'Chat', link: '/chat', icon: <FaComments /> },
    { name: 'Documentação', link: '/docs', icon: <FaBook /> },
  ];

  const handleNavigateToChat = () => {
    navigate('/chat');  // Redireciona para a rota /chat
  };

  return (
    <div className={`page-container ${isDarkMode ? 'dark-mode' : ''}`}>
      {/* Usando o componente Navbar */}
      <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode} sidebar="sim" options={sidebarOptions} />

      <div className="content-wrap">
        <main>
          <section className="welcome-section">
            <h1>Bem-vindo ao Chatbot de Treinamento</h1>
            <p>Um assistente virtual para dar treinamento completo sobre o SIGMA.</p>
            <div className="button-group">
              <button className="cta-button" onClick={handleNavigateToChat}>
                Comece a Aprender
              </button>
            </div>
          </section>

          <section className="features-section">
            <h2>Como o Chatbot Funciona</h2>
            <div className="grid grid-cols-3 gap-8">
              <div className="card">
                <FaComments className="icon" />
                <h3>Faça sua Pergunta</h3>
                <p>Digite sua dúvida sobre qualquer aspecto do nosso sistema de gestão no chat.</p>
              </div>
              <div className="card">
                <FaRocket className="icon" />
                <h3>Processamento IA</h3>
                <p>Nossa IA analisa sua pergunta e busca as informações mais relevantes em nossa base de conhecimento.</p>
              </div>
              <div className="card">
                <FaCheckCircle className="icon" />
                <h3>Resposta Personalizada</h3>
                <p>Receba uma resposta clara e personalizada, com exemplos práticos e links para mais informações.</p>
              </div>
            </div>
          </section>

          <section className="resources-section">
            <h2>Recursos do Chatbot</h2>
            <div className="grid grid-cols-2 gap-8">
              <div className="card">
                <h3>Recursos Avançados</h3>
                <ul>
                  <li><FaBook /> Biblioteca completa de todos os módulos do sistema</li>
                  <li><FaClock /> Respostas rápidas e precisas em segundos</li>
                  <li><FaRocket /> Integração com outras ferramentas da empresa</li>
                </ul>
              </div>
              <div className="card">
                <h3>Benefícios para Sua Equipe</h3>
                <ul>
                  <li><FaCheckCircle /> Aumento significativo na produtividade da equipe</li>
                  <li><FaCheckCircle /> Redução no tempo de treinamento de novos funcionários</li>
                  <li><FaCheckCircle /> Melhoria na compreensão e utilização do sistema</li>
                </ul>
              </div>
            </div>
          </section>

          <section className="faq-section">
            <h2>Perguntas Frequentes</h2>
            <div className="grid grid-cols-2 gap-6">
              <div className="card">
                <h3>Sobre o Chatbot</h3>
                <details>
                  <summary>Como o Chatbot é atualizado?</summary>
                  <p>Nosso time atualiza regularmente a base de dados com informações atualizadas.</p>
                </details>
                <details>
                  <summary>O Chatbot é personalizável?</summary>
                  <p>Sim, pode ser ajustado para diferentes departamentos e setores.</p>
                </details>
              </div>
              <div className="card">
                <h3>Uso e Funcionalidades</h3>
                <details>
                  <summary>O Chatbot pode ser acessado fora do trabalho?</summary>
                  <p>Sim, pode ser acessado de qualquer lugar com uma conexão segura.</p>
                </details>
                <details>
                  <summary>O Chatbot pode realizar tarefas além de perguntas?</summary>
                  <p>Sim, ele pode auxiliar em tarefas como agendamento e geração de relatórios simples.</p>
                </details>
              </div>
            </div>
          </section>

          <section className="cta-section">
            <h2>Pronto para Revolucionar seu Trabalho?</h2>
            <p>Junte-se aos milhares que já estão aproveitando o poder do Chatbot.</p>
            <div className="grid grid-cols-3 gap-4">
              <div className="cta-card">
                <FaClock className="icon" />
                <h3>Economize Tempo</h3>
                <p>Reduza o tempo gasto em buscas por informações em até 50%.</p>
              </div>
              <div className="cta-card">
                <FaCheckCircle className="icon" />
                <h3>Aumente a Precisão</h3>
                <p>Obtenha respostas precisas e consistentes, reduzindo erros.</p>
              </div>
              <div className="cta-card">
                <FaRocket className="icon" />
                <h3>Produtividade</h3>
                <p>Aumente sua produtividade em até 30% com respostas instantâneas.</p>
              </div>
            </div>
            <button className="cta-button" onClick={handleNavigateToChat}>
              Comece a Usar o Chatbot Agora
            </button>
          </section>
        </main>
      </div>

      {/* Usando o componente Footer */}
      <Footer isDarkMode={isDarkMode} />
    </div>
  );
}

export default Home;
